import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import SignInComponent from "../components/SignIn";
import { matchPath } from "react-router";
import Tabs from "../components/Tabs";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { useSignInGoogle, useHello } from "../graphql/useUser";
import { loginUser, updateUserData, getUser, getPushToken } from "../actions/user";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../components/Loading";
import swal from "@sweetalert/with-react";
import { useHotels } from "../graphql/useHotels";
import { FirebaseGetNewToken } from "../firebase";
import { setPushToken } from "../actions/notifications";
import { getDasUrl, getLogoRef } from "../actions/config";
import { isLight } from "actions/ui";
import NewTerms from "../components/NewTerms";
import { getSelectedHotel } from "actions/hotel";

const SignIn = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { loading: loadingHH, error: errorHH, hotels } = useHotels({ autoload: true });
    const stateRedirect = useRef({});
    const pushToken = useSelector(getPushToken);
    const currentUser = useSelector(getUser);
    const project = useSelector(getSelectedHotel);
    const [redirectTo, setRedirectTo] = useState("");
    const [updatedTerms, setUpdatedTerms] = useState(null);
    const dispatch = useDispatch();
    const { hello } = useHello();
    const [authenticating, setAuthenticating] = useState(false);

    const locationPrecheckinSendSuccess = matchPath(location.pathname, { path: "/stays" });
    const locationProfile = matchPath(location.pathname, { path: "/profile" });
    const locationHome = matchPath(location.pathname, { path: "/signin" });
    const locationBooking = matchPath(location.pathname, {
        path: "/site/booking",
    });

    const locationAddRoom = matchPath(location.pathname, {
        path: "/site/addroom",
    });
    const locationZones = matchPath(location.pathname, { path: "/site/zones" });
    const locationCuiner = matchPath(location.pathname, { path: "/pos" });

    useEffect(() => {
        if (locationHome) {
            document.getElementById("fullContainer").classList.add("bg-login");
        }
        return () => {
            document.getElementById("fullContainer").classList.remove("bg-login");
        };
    }, []);

    const getRedirectAfterLogin = () => {
        const path = locationProfile
            ? "/profile"
            : locationBooking
            ? "/site/booking"
            : locationZones
            ? "/site/zones"
            : locationCuiner
            ? "/pos/cart"
            : "/site";
        return path;
    };

    const loginSuccess = ({ user, token }) => {
        if (user && token) {
            FirebaseGetNewToken().then((token) => {
                dispatch(setPushToken(token));
                hello({ variables: { push: token } });
            });
            dispatch(loginUser(user.ref, token));
            dispatch(updateUserData(user, "google"));
            toast(t("hello user", { name: user.name }) + "\n" + t("welcome message"));
            setRedirectTo(getRedirectAfterLogin());
        }
    };

    const { signInGoogle, loading } = useSignInGoogle({
        onCompleted: (data) => {
            setAuthenticating(false);
            const user = data.signInGoogle.user;
            const token = data.signInGoogle.auth.token;
            if (user && token) {
                if (!user.termsAccepted) {
                    setUpdatedTerms({ user, token });
                } else {
                    stateRedirect.current = { siginInMethod: "GOOGLE" };
                    setTimeout(() => {
                        loginSuccess({ user, token });
                    }, 100);
                }
            } else {
                signinError();
            }
        },
        onError: (error) => {
            console.error(error);
            signinError();
        },
    });

    const signinError = () => {
        setAuthenticating(false);
        swal({
            title: t("error.signinTitle"),
            text: t("error.unknown"),
            button: t("alert ok"),
        });
        setAuthenticating(false);
    };

    const onGoogleToken = (token) => {
        setAuthenticating(true);
        signInGoogle({
            variables: {
                token: token,
                push: pushToken,
            },
        });
    };

    const onGoogleError = (error) => {
        setAuthenticating(false);
    };

    let locationSection = "login";
    if (locationAddRoom) {
        locationSection = "add_room";
    } else if (locationBooking) {
        locationSection = "my_bookings";
    } else if (locationZones) {
        locationSection = "hotel_areas";
    } else if (locationProfile) {
        locationSection = "profile";
    }
    const sectionName = locationSection;

    //portal && portal.rooms

    if (currentUser) {
        return (
            <Redirect
                push
                to={{
                    pathname: getRedirectAfterLogin(),
                    state: stateRedirect,
                }}
            />
        );
    }

    if (redirectTo) {
        return (
            <Redirect
                push
                to={{
                    pathname: redirectTo,
                    state: stateRedirect,
                }}
            />
        );
    }

    const NewTermsView = (
        <NewTerms
            token={updatedTerms ? updatedTerms.token : null}
            onSuccess={() => {
                loginSuccess(updatedTerms);
            }}
        />
    );
    const SignInView = (
        <SignInComponent
            sectionName={sectionName}
            onClick={() => {}}
            onGoogleToken={onGoogleToken}
            onGoogleError={onGoogleError}
        />
    );

    if (locationPrecheckinSendSuccess) {
        return (
            <section className="basic has-top has-tabs">
                <Header back={"/stays/status=upcoming"} title={t("pre-check-in")} />
                <section>
                    <div className=" w-full mb-8 rounded bg-green-100 flex items-center justify-center py-3 text-white">
                        <i className=" icon icon-checkmark mr-2"></i> <span>{t("pre-check-in-successfully-sent")}</span>{" "}
                    </div>
                    <p className="text-left mt-3 mb-6">{t("do-you-want-to-associate-the-booking-to-your-account")}</p>
                    <div className="w-full border shadow-md text-center rounded-lg p-5">
                        {loading || (authenticating && <Loading />)}
                        <div className={loading || authenticating ? "invisible" : ""}>
                            {updatedTerms ? NewTermsView : SignInView}
                        </div>
                    </div>
                    <div className="p-5 w-full">
                        <Link
                            id="signin-anonymous"
                            to={{
                                pathname: "/stays",
                                state: "ACCESS_BY_GUEST",
                            }}
                            className="btn btn-secondary block mx-auto my-5 w-full"
                        >
                            {t("enter anonymously")}
                        </Link>
                    </div>
                </section>
                {/* <Tabs /> */}
            </section>
        );
    } else if (locationHome) {
        return (
            <>
                <div className="flex flex-wrap content-start min-h-screen max-w-lg m-auto px-8">
                    <div className="py-12 text-center w-full">
                        <img
                            src={`${getDasUrl()}/${getLogoRef(isLight(window.appConfig?.theme?.login))}`}
                            className="mx-auto"
                            style={{ maxHeight: "6rem", maxWidth: "66.666667%" }}
                            onError={(e) => {
                                e.target.style.display = "none";
                            }}
                        />
                    </div>
                    <div className="bg-primary w-full text-primary-contrast border shadow-md text-center rounded-lg p-5">
                        {loading || (loadingHH && !errorHH) ? <Loading /> : ""}
                        <div className={loading ? "opacity-25" : ""}>{updatedTerms ? NewTermsView : SignInView}</div>
                    </div>
                    <div className="p-5 w-full">
                        <Link
                            id="signin-anonymous"
                            to={{
                                pathname: "/site/list",
                                state: "ACCESS_BY_GUEST",
                            }}
                            className="btn btn-secondary block mx-auto my-5 w-full"
                        >
                            {t("enter anonymously")}
                        </Link>
                    </div>
                </div>
            </>
        );
    } else if (locationProfile) {
        return (
            <section className="w-full bg-login has-tabs">
                <section className=" max-w-lg m-auto py-6 px-4">
                    <Link id="action-config" to="config" className="text-2xl absolute">
                        <i className="icon icon-gear"></i>
                    </Link>
                    <div className="py-12">
                        <img
                            src={`${getDasUrl()}/${getLogoRef(isLight(window.appConfig?.theme?.login))}`}
                            className="mx-auto"
                            style={{ maxHeight: "6rem", maxWidth: "66.666667%" }}
                            onError={(e) => {
                                e.target.style.display = "none";
                            }}
                        />
                    </div>
                    <div className="bg-primary w-full text-primary-contrast border shadow-md text-center rounded-lg p-5">
                        {loading || (authenticating && <Loading />)}
                        <div className={loading || authenticating ? "invisible" : ""}>
                            {updatedTerms ? NewTermsView : SignInView}
                        </div>
                    </div>
                </section>
                <Tabs />
            </section>
        );
    } else {
        const title = locationBooking ? t("my hotel booking") : locationAddRoom ? t("add room") : t("login title");
        const description = locationBooking
            ? t("account needed to my booking")
            : locationAddRoom
            ? t("account needed to add room")
            : t("account needed to book");
        return (
            <section className="basic has-top has-tabs">
                <Header title={title} />
                <section>
                    <p className="text-center mt-3 mb-8">{description}</p>
                    <div className="w-full border shadow-md text-center rounded-lg p-5">
                        {loading || (authenticating && <Loading />)}
                        <div className={loading || authenticating ? "invisible" : ""}>
                            {updatedTerms ? NewTermsView : SignInView}
                        </div>
                    </div>
                </section>
                <Tabs />
            </section>
        );
    }
};

export default SignIn;
