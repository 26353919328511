import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

const TextInput = ({ placeholder = "", name = "name", onChange, value, isRequired, error, errorMsg, disabled }) => {
    const { t } = useTranslation();
    const [currentValue, setCurrentValue] = useState("");
    const inputRef = useRef(null);
    const selectionRef = useRef({ start: 0, end: 0 });

    useLayoutEffect(() => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(selectionRef.current.start, selectionRef.current.end);
        }
    }, [currentValue]);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleChange = (e) => {
        selectionRef.current = {
            start: e.target.selectionStart,
            end: e.target.selectionEnd,
        };

        setCurrentValue(e.target.value);

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className="w-full">
            <label
                className={`${disabled ? " text-neutral-contrast-40" : ""} block text-sm mb-1`}
                htmlFor={`text-input-${name}-input`}
            >
                {t(name)}
                {isRequired && !disabled ? " *" : ""}
            </label>
            <input
                ref={inputRef}
                onChange={handleChange}
                disabled={disabled}
                className={error ? "error" : ""}
                value={currentValue}
                id={`text-input-${name}-input`}
                type="text"
                placeholder={t(placeholder)}
            />
            {error && errorMsg ? <div className="text-error float-right text-sm">{errorMsg}</div> : null}
        </div>
    );
};

export default TextInput;
