import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { usePortal } from "../graphql/useHotels";
import usePortalData from "../hooks/usePortalData";
import useWifiService from "../hooks/useWifiService";
import ErrorPage from "../components/ErrorPage";
import Loading from "../components/Loading";
import Landing from "./Landing";
import { setSelectedLanding, setSelectedHotel } from "../actions/hotel";
import { mikrotikSimulator, getDasUrl } from "../actions/config";
import { portalConfig, updatePortalSession, loadPortalSession, getPortalSession } from "../actions/portal";
import jwtEncode from "jwt-encode";
import { storagePrefix } from "../localStorage";

const searchParams = new URLSearchParams(window.location.search);
const paramToken = searchParams ? searchParams.get("token") : null;
const googleToken = searchParams ? searchParams.get("code") : null;
const paramHash = searchParams ? searchParams.get("hash") : null;
const testingMikrotik = mikrotikSimulator();

if (paramToken) {
    // Clean previous data if exists
    if (localStorage) {
        localStorage.removeItem("portalWiFi");
        localStorage.removeItem(storagePrefix + "_session");
    }
}

const Portal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loadPortal, loading: loadingHotel, error: errorHotel, data } = usePortal();
    const { sessionKey, projectRef, chainRef, error: portalError, session: portalSession } = usePortalData();
    const {
        updateUserRadius,
        loading: gettingToken,
        error: errorGettingToken,
        getPortalTokenFromSession,
    } = useWifiService();

    const [configReady, setPortalConfigReady] = useState(false);
    const [sessionReady, setPortalSessionReady] = useState(false);

    const session = useSelector(getPortalSession);

    const hotelData = data ? data.getProject : null;
    const hotelName = hotelData ? hotelData.name : null;
    const portalData = hotelData ? { ...hotelData.portal, projectName: hotelName } : null;
    const hasPortal = portalData && portalData.desktop ? true : false;

    const layout = portalSession ? portalSession.layout : null;
    const terms = portalSession && portalSession.config ? portalSession.config.accessConditions : null;
    const privacy = portalSession && portalSession.config ? portalSession.config.privacyPolicy : null;
    const redirectToMikrotik = session.redirectToMikrotik(chainRef, projectRef, sessionKey);
    const currentStep = session ? session.getStep() : null;
    const returnURL = session ? session.returnURL(chainRef, projectRef, sessionKey) : null;

    const [openBrowserError, setOpenBrowserError] = useState(null);
    const [openbrowserReady, setOpenbrowserReady] = useState(
        !session || session.inBrowser || !session.redirect || !session.redirect.pwa
    );
    const [comingOut, setComingOut] = useState(false);

    const openLink = useRef(null);

    useEffect(() => {
        if (googleToken) {
            dispatch(
                updatePortalSession({
                    google: "code=" + googleToken + "&origin=" + encodeURIComponent(window.location.origin + "/portal"),
                })
            );
        } else if (paramToken) {
            dispatch(loadPortalSession({ token: paramToken, hash: paramHash }));
        } else {
            dispatch(loadPortalSession(null));
        }
        setPortalSessionReady(true);
    }, []);

    const dispatchEvent = (event, element) => {
        return element
            ? element.dispatchEvent(
                  new MouseEvent(event, {
                      bubbles: true,
                      cancelable: true,
                      view: window,
                  })
              )
            : null;
    };

    const click = (elem) => {
        if (elem) {
            let resultClick = dispatchEvent("click", elem);
            dispatchEvent("touchend", elem); // Esto para cuando iOS no permite click
            if (!resultClick) {
                elem.style.opacity = 100;
                document.location.href = elem.href;
            }
            setComingOut(true);
        }
    };

    useLayoutEffect(() => {
        if (currentStep === "openbrowser" && openbrowserReady && !comingOut) {
            if (openLink && openLink.current) {
                click(openLink.current);
            } else {
                setTimeout(() => {
                    if (openLink && openLink.current) {
                        click(openLink.current);
                    }
                }, 3000);
            }
        }
    }, [openLink.current, currentStep, openbrowserReady]);

    useEffect(() => {
        if (projectRef) {
            loadPortal({ variables: { ref: projectRef } });
            dispatch(setSelectedHotel(projectRef));
        }
    }, [projectRef]);

    useEffect(() => {
        document.title = hotelName ? hotelName + " Wi-Fi" : "Wi-Fi";
        if (portalData) {
            dispatch(portalConfig(portalData));
        }
        setPortalConfigReady(true);
    }, [data]);

    useEffect(() => {
        if (layout) {
            let selectedLandingData = null;
            if (layout && layout.desktop && layout.desktop.ref > 0) {
                selectedLandingData = {
                    mainLandingRef: layout.desktop.ref,
                    landingRef: layout.desktop.ref,
                    designRef: hotelData ? hotelData.designRef : null,
                    history: [],
                    theme: hotelData ? hotelData.theme : null,
                    vodDomain: hotelData ? hotelData.VODDomain : null,
                };
            }
            dispatch(setSelectedLanding(selectedLandingData));
        }
    }, [layout, data]);

    useEffect(() => {
        if (redirectToMikrotik && !comingOut) {
            const form = document.getElementById("post-form");
            if (form) {
                form.submit();
            }
        }
    }, [redirectToMikrotik]);

    useEffect(() => {
        if (sessionReady && session && session.sessionKey && session.sessionKey != sessionKey) {
            getPortalTokenFromSession(session.sessionKey)
                .then((payload) => {
                    if (payload) {
                        const token = jwtEncode(payload, "zafiro");
                        if (token) {
                            localStorage.setItem("portalWiFi", token);
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    }, [sessionReady, session]);

    useEffect(() => {
        if (currentStep === "openbrowser" && !openbrowserReady && !comingOut && projectRef) {
            updateUserRadius()
                .then(() => {
                    setOpenBrowserError(false);
                    setOpenbrowserReady(true);
                    dispatch(updatePortalSession({ inBrowser: true }));
                })
                .catch((e) => {
                    setOpenBrowserError(e?.message || e);
                    setOpenbrowserReady(false);
                    dispatch(updatePortalSession({ inBrowser: false }));
                    console.error(e);
                });
        }
    }, [currentStep, openbrowserReady, projectRef]);

    const error = errorHotel || errorGettingToken || portalError || openBrowserError;

    const loading =
        !configReady ||
        !sessionReady ||
        loadingHotel ||
        gettingToken ||
        comingOut ||
        (currentStep === "openbrowser" && !openbrowserReady);

    const styles = portalData && portalData.desktop ? portalData.desktop.style : null;

    if (loading && !error) {
        return (
            <BG styles={styles}>
                <Loading />
            </BG>
        );
    } else if (error) {
        return error.message === "unauthorized" ? (
            <ErrorPage type="404" retry={false} error={error} />
        ) : (
            <ErrorPage title={t("error.title")} error={error} />
        );
    } else if (!hasPortal) {
        return <ErrorPage type="no-portal" retry={false} />;
    } else if (!terms || !privacy) {
        return <ErrorPage type="config-terms" />;
    } else if (currentStep === "openbrowser") {
        return (
            <BG styles={styles}>
                <div className="relative text-center p-10 w-full text-xs">
                    <a ref={openLink} href={returnURL} className="btn btn-primary px-10 cursor-pointer opacity-0">
                        {t("continue")}
                    </a>
                </div>
            </BG>
        );
    } else if (redirectToMikrotik) {
        return (
            <BG styles={styles}>
                <form
                    id="post-form"
                    method={testingMikrotik ? "get" : "post"}
                    action={testingMikrotik ? "/mikrotik-simulator" : redirectToMikrotik.url}
                >
                    {testingMikrotik ? <input type="text" name="action" value={redirectToMikrotik.url} /> : null}
                    {Object.keys(redirectToMikrotik.data).map((key) => {
                        return <input type="hidden" name={key} value={redirectToMikrotik.data[key]} />;
                    })}
                </form>
            </BG>
        );
    } else if (currentStep === "redirect") {
        return (
            <BG styles={styles}>
                <div className="relative text-center p-10 w-full z-10">{t("welcome message")}</div>
            </BG>
        );
    } else {
        return (
            <BG styles={styles}>
                <Landing data={hotelData} isPortal={true} />
            </BG>
        );
    }
};

const BG = ({ children, styles }) => {
    const { i18n } = useTranslation();

    const bgColor = styles ? styles.bgColor : null;
    const fgColor = styles ? styles.fgColor : null;
    const bgStyle = { color: fgColor, backgroundColor: bgColor };

    const lang = (i18n.language || "").match(/^[^-]+/)[0];
    const dasURL = getDasUrl();
    let dynamicObject = null;
    if (styles && styles.images && styles.images.length > 0) {
        if (styles.images[0].libraryRef) {
            dynamicObject = `${dasURL}/${styles.images[0].libraryRef}?lang=${lang}`;
        } else {
            dynamicObject = styles.images[0].externalUrl;
        }
    }
    let css = {};
    if (dynamicObject) {
        if (css.backgroundColor) delete css.backgroundColor;
        if (css.disabledBgColor) delete css.disabledBgColor;
        css.position = "absolute";
        css.left = 0;
        css.top = 0;
        css.right = 0;
        css.bottom = 0;
        css.zIndex = 0;
        css.backgroundImage = `url('${dynamicObject}')`;
        css.backgroundPosition = "center";
        css.backgroundSize = "cover";
        css.backgroundRepeat = "no-repeat";
        css.backgroundAttachment = "fixed";
        if (styles.opacity) css.opacity = styles.opacity / 100;
        if (styles.bgColor) css.backgroundColor = styles.bgColor;
    }
    const bgImageStyle = css
        ? {
              ...css,
              position: "absolute",
              left: "auto",
              top: css.top || 0,
              width: css.width || "100%",
              height: css.height || "100%",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              zIndex: 0,
          }
        : null;

    return (
        <div className="w-full" style={bgStyle}>
            {bgImageStyle ? <div id="img-background" style={bgImageStyle}></div> : null}
            {children}
        </div>
    );
};

export default Portal;
