import React, { useEffect, useState, useRef } from "react";
import { decode as base64_decode } from "base-64";
import { Link, Redirect, useLocation, useParams } from "react-router-dom";
import SignInEmailComponent from "../components/SignInEmail";
import { matchPath } from "react-router";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loading";
import { useSignIn, useHello } from "../graphql/useUser";
import { loginUser, updateUserData, getPushToken } from "../actions/user";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import swal from "@sweetalert/with-react";
import { FirebaseGetNewToken } from "../firebase";
import { setPushToken } from "../actions/notifications";
import { getDasUrl, getLogoRef } from "../actions/config";
import { isLight } from "actions/ui";
import NewTerms from "../components/NewTerms";
import { setVerifiedToken } from "../actions/integrations";

const SignInEmail = () => {
    const { t, i18n } = useTranslation();
    const { email } = useParams();
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const location = useLocation();
    const stateRedirect = useRef({});
    const [redirectTo, setRedirectTo] = useState("");
    const [updatedTerms, setUpdatedTerms] = useState(null);
    const [lastPass, setLastPass] = useState("");
    const dispatch = useDispatch();
    const pushToken = useSelector(getPushToken);
    const verifiedToken = useSelector((state) => state.session.verifiedToken);
    const { hello } = useHello();

    const locationHome = matchPath(location.pathname, { path: "/signin" });
    const locationProfile = matchPath(location.pathname, { path: "/profile" });
    const locationBooking = matchPath(location.pathname, {
        path: "/site/booking",
    });
    const locationAddRoom = matchPath(location.pathname, {
        path: "/site/addroom",
    });
    const locationZones = matchPath(location.pathname, { path: "/site/zones" });
    const locationCuiner = matchPath(location.pathname, { path: "/pos" });

    let locationSection = "login";
    if (locationAddRoom) {
        locationSection = "add_room";
    } else if (locationBooking) {
        locationSection = "my_bookings";
    } else if (locationZones) {
        locationSection = "hotel_areas";
    } else if (locationProfile) {
        locationSection = "profile";
    }
    const sectionName = locationSection;

    let redirectAfterLogin = "/site";
    if (locationProfile) {
        redirectAfterLogin = "/profile";
    } else if (locationBooking) {
        redirectAfterLogin = "/site/booking";
    } else if (locationZones) {
        redirectAfterLogin = "/site/zones";
    } else if (locationCuiner) {
        redirectAfterLogin = "/pos/cart";
    }
    const signinError = () => {
        swal({
            title: t("error.signinTitle"),
            text: t("error.signin"),
            button: t("alert ok"),
        });
    };

    const loginSuccess = ({ user, token }) => {
        if (user && token) {
            FirebaseGetNewToken().then((token) => {
                dispatch(setPushToken(token));
                hello({ variables: { push: token } });
            });
            dispatch(loginUser(user.ref, token, lastPass));
            dispatch(updateUserData(user, "password"));
            toast(t("hello user", { name: user.name }) + "\n" + t("welcome message"));
            setRedirectTo(redirectAfterLogin);
        }
    };

    const { signIn, loading } = useSignIn({
        onCompleted: (data) => {
            const user = data.signIn.user;
            const token = data.signIn.auth.token;
            if (user && token) {
                if (!user.termsAccepted) {
                    setUpdatedTerms({ user, token });
                } else {
                    loginSuccess({ user, token });
                }
            } else {
                signinError();
            }
        },
        onError: (error) => {
            signinError();
        },
    });

    const onSubmit = (data) => {
        stateRedirect.current = { siginInMethod: "EMAIL" };
        if (data.password) setLastPass(data.password);
        signIn({
            variables: {
                email: data.email || email,
                password: data.password || null,
                code: data.code || null,
                language: i18nLang,
                push: pushToken,
            },
        });
    };

    useEffect(() => {
        if (locationHome) {
            document.getElementById("fullContainer").classList.add("bg-login");
        }
        return () => {
            document.getElementById("fullContainer").classList.remove("bg-login");
        };
    }, []);

    useEffect(() => {
        if (verifiedToken) {
            const [email, code] = base64_decode(verifiedToken).split(":::");
            onSubmit({
                email: email,
                code: code,
            });
            dispatch(setVerifiedToken(null));
        }
    }, [verifiedToken]);

    if (redirectTo) {
        return (
            <Redirect
                push
                to={{
                    pathname: redirectTo,
                    state: stateRedirect,
                }}
            />
        );
    }

    const NewTermsView = (
        <NewTerms
            token={updatedTerms ? updatedTerms.token : null}
            onSuccess={() => {
                loginSuccess(updatedTerms);
            }}
        />
    );
    const SignInView = <SignInEmailComponent sectionName={sectionName} onSubmit={onSubmit} />;

    if (locationHome) {
        return (
            <>
                <div className="flex flex-wrap content-start min-h-screen max-w-lg m-auto px-8">
                    <div className="pt-12 text-center w-full">
                        <img
                            src={`${getDasUrl()}/${getLogoRef(isLight(window.appConfig?.theme?.login))}`}
                            onError={(e) => {
                                e.target.style.display = "none";
                            }}
                            style={{ maxHeight: "6rem", maxWidth: "66.666667%" }}
                            className="mx-auto"
                        />
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="bg-primary w-full mt-12 text-primary-contrast border border-gray-200 shadow-md text-center rounded-lg p-5">
                                {updatedTerms ? (
                                    NewTermsView
                                ) : (
                                    <>
                                        {email ? null : (
                                            <div className="flex justify-between mb-4">
                                                <Link to="../">
                                                    <i className="icon icon-back"></i>
                                                </Link>
                                                <Link to="../signup" className="text-accent text-right font-bold">
                                                    {t("signup")}
                                                </Link>
                                            </div>
                                        )}
                                        {SignInView}
                                    </>
                                )}
                            </div>
                            <div className="p-5 w-full">
                                <Link
                                    to={{
                                        pathname: redirectAfterLogin,
                                        state: "ACCESS_BY_GUEST",
                                    }}
                                    id="signin-anonymous"
                                    className="btn btn-secondary block mx-auto my-5 w-full"
                                >
                                    {t("enter anonymously")}
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    } else {
        return (
            <section className="basic has-top">
                <Header title={t("signin")} />
                <section>
                    {loading ? (
                        <Loading />
                    ) : updatedTerms ? (
                        NewTermsView
                    ) : (
                        <>
                            <p className="text-center mt-3 mb-8">{t("enter your email and pass")}</p>
                            <div className="text-center">{SignInView}</div>
                        </>
                    )}
                </section>
            </section>
        );
    }
};

export default SignInEmail;
